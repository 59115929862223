import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_REGEX = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(.*[!@#$%^&*+=\\\\?\\-<>|(){}\'\\";:/€].*)');
export const SEARCH_INPUT_DEBOUNCE_TIME = 500;

export const MILISECONDS_IN_AN_MINUTE = 60000;

export const MILISECONDS_IN_A_DAY = 86400000;

export const MILISECONDS_SEARCH = 1800000; // 30 minutes

export const TIMEPICKER_THEME: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: '#fff'
  },
  dial: {
    dialBackgroundColor: '#0097D7'
  },
  clockFace: {
    clockFaceBackgroundColor: '#f8f8f8',
    clockHandColor: '#0097D7',
    clockFaceTimeInactiveColor: '#0097D7'
  }
};

export const DEFAULT_IMAGE = '/assets/images/bus-default-color.png';

/**
 * Spain provinces
 */
export const PROVINCES = [
  'A Coruña',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Barcelona',
  'Burgos',
  'Cáceres',
  'Cádiz',
  'Cantabria',
  'Castellón',
  'Ceuta',
  'Ciudad Real',
  'Córdoba',
  'Cuenca',
  'Girona',
  'Granada',
  'Guadalajara',
  'Guipúzcoa',
  'Huelva',
  'Huesca',
  'Islas Baleares',
  'Jaén',
  'La Rioja',
  'Las Palmas',
  'León',
  'Lleida',
  'Lugo',
  'Madrid',
  'Málaga',
  'Melilla',
  'Murcia',
  'Navarra',
  'Ourense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza'
];

export const URLPROVINCES = [
  'a-coruna',
  'alava',
  'albacete',
  'alicante',
  'almeria',
  'asturias',
  'avila',
  'badajoz',
  'barcelona',
  'burgos',
  'caceres',
  'cadiz',
  'cantabria',
  'castellon',
  'ceuta',
  'ciudad-real',
  'cordoba',
  'cuenca',
  'girona',
  'granada',
  'guadalajara',
  'guipuzcoa',
  'huelva',
  'huesca',
  'ibiza',
  'jaen',
  'la-rioja',
  'las-palmas',
  'leon',
  'lleida',
  'lugo',
  'madrid',
  'malaga',
  'mallorca',
  'melilla',
  'menorca',
  'murcia',
  'navarra',
  'ourense',
  'palencia',
  'pontevedra',
  'salamanca',
  'segovia',
  'sevilla',
  'soria',
  'tarragona',
  'tenerife',
  'teruel',
  'toledo',
  'valencia',
  'valladolid',
  'vizcaya',
  'zamora',
  'zaragoza'
];

/**
 * Minimum amount for bank transfer payment
 */
export const BANK_TRANSFER_MIN_AMOUNT = 950;

/**
 * Domains
 */
export const DOMAINS = [
  'localhost',
  'dev.rent-a-bus.nucleoo.com',
  'uat.rent-a-bus.nucleoo.com',
  'movenbus.com',
  'movenbus.es'
];

export const SUBDOMAINS = ['dev', 'uat', 'localhost', 'movenbus'];

/**
 * SEO URLs
 */
export const RENT_BUS_ES = 'alquilar-autobus';
export const RENT_BUS_EN = 'rent-bus';
